<template>
    <div>
        <cosecha-urgente></cosecha-urgente>
        <screenshot name-pdf="Lineas y Costos"></screenshot>
        <good-table-basic title="" :data-table="dataTable"></good-table-basic>
    </div>
</template>

<script>

import GoodTableBasic from "./GoodTableBasic.vue";
import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
import Screenshot from "@/views/clients/camanchaca/components/Screenshot";

export default {
    components: {
        GoodTableBasic,
        CosechaUrgente,
        Screenshot
        
    },
    data() {
        return {
            dataTable: {
                planning: [
                    {
                        linea: "Primaria",
                        subLinea: "San José",
                        unidadPerido: "Piezas",
                        capacidadPeriodo_01: "30.000",
                        costoAdicional_01: "",
                        capacidadPeriodo_02: "30.000",
                        costoAdicional_02: "",
                        capacidadPeriodo_03: "30.000",
                        costoAdicional_03: "",
                        capacidadPeriodo_04: "30.000",
                        costoAdicional_04: "",
                        capacidadPeriodo_05: "30.000",
                        costoAdicional_05: "",
                        capacidadPeriodo_06: "30.000",
                        costoAdicional_06: "",
                        capacidadPeriodo_07: "30.000",
                        costoAdicional_07: "",
                        capacidadPeriodo_08: "30.000",
                        costoAdicional_08: "",
                        capacidadPeriodo_09: "30.000",
                        costoAdicional_09: ""
                    },
                    {
                        linea: "Primaria",
                        subLinea: "Surproceso",
                        unidadPerido: "Piezas",
                        capacidadPeriodo_01: "15.000",
                        costoAdicional_01: "0,2",
                        capacidadPeriodo_02: "15.000",
                        costoAdicional_02: "0,2",
                        capacidadPeriodo_03: "15.000",
                        costoAdicional_03: "0,2",
                        capacidadPeriodo_04: "15.000",
                        costoAdicional_04: "0,2",
                        capacidadPeriodo_05: "15.000",
                        costoAdicional_05: "0,2",
                        capacidadPeriodo_06: "15.000",
                        costoAdicional_06: "0,2",
                        capacidadPeriodo_07: "15.000",
                        costoAdicional_07: "0,2",
                        capacidadPeriodo_08: "15.000",
                        costoAdicional_08: "0,2",
                        capacidadPeriodo_09: "15.000",
                        costoAdicional_09: "0,2"
                    },
                    {
                        linea: "Entero fresco",
                        subLinea: "San José",
                        unidadPerido: "Piezas",
                        capacidadPeriodo_01: "30.000",
                        costoAdicional_01: "",
                        capacidadPeriodo_02: "30.000",
                        costoAdicional_02: "",
                        capacidadPeriodo_03: "30.000",
                        costoAdicional_03: "",
                        capacidadPeriodo_04: "30.000",
                        costoAdicional_04: "",
                        capacidadPeriodo_05: "30.000",
                        costoAdicional_05: "",
                        capacidadPeriodo_06: "30.000",
                        costoAdicional_06: "",
                        capacidadPeriodo_07: "30.000",
                        costoAdicional_07: "",
                        capacidadPeriodo_08: "30.000",
                        costoAdicional_08: "",
                        capacidadPeriodo_09: "30.000",
                        costoAdicional_09: ""
                    },
                    {
                        linea: "Filete",
                        subLinea: "Tomé",
                        unidadPerido: "Piezas",
                        capacidadPeriodo_01: "32.000",
                        costoAdicional_01: "",
                        capacidadPeriodo_02: "32.000",
                        costoAdicional_02: "",
                        capacidadPeriodo_03: "32.000",
                        costoAdicional_03: "",
                        capacidadPeriodo_04: "32.000",
                        costoAdicional_04: "",
                        capacidadPeriodo_05: "32.000",
                        costoAdicional_05: "",
                        capacidadPeriodo_06: "32.000",
                        costoAdicional_06: "",
                        capacidadPeriodo_07: "32.000",
                        costoAdicional_07: "",
                        capacidadPeriodo_08: "32.000",
                        costoAdicional_08: "",
                        capacidadPeriodo_09: "32.000",
                        costoAdicional_09: ""
                    },
                    {
                        linea: "Filete",
                        subLinea: "Externo",
                        unidadPerido: "Piezas",
                        capacidadPeriodo_01: "90.000",
                        costoAdicional_01: "0,3",
                        capacidadPeriodo_02: "90.000",
                        costoAdicional_02: "0,3",
                        capacidadPeriodo_03: "90.000",
                        costoAdicional_03: "0,3",
                        capacidadPeriodo_04: "90.000",
                        costoAdicional_04: "0,3",
                        capacidadPeriodo_05: "90.000",
                        costoAdicional_05: "0,3",
                        capacidadPeriodo_06: "90.000",
                        costoAdicional_06: "0,3",
                        capacidadPeriodo_07: "90.000",
                        costoAdicional_07: "0,3",
                        capacidadPeriodo_08: "90.000",
                        costoAdicional_08: "0,3",
                        capacidadPeriodo_09: "90.000",
                        costoAdicional_09: "0,3"
                    },
                    // {
                    //     linea: "Filete fresco",
                    //     subLinea: "Tomé",
                    //     unidadPerido: "Piezas",
                    //     capacidadPeriodo_01: "14.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "14.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "14.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "14.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "14.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "14.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "14.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "14.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "14.000",
                    //     costoAdicional_09: ""
                    // },
                    // {
                    //     linea: "Sellado Filete-MP Congelado",
                    //     subLinea: "Tomé",
                    //     unidadPerido: "Piezas",
                    //     capacidadPeriodo_01: "15.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "15.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "15.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "15.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "15.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "15.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "15.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "15.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "15.000",
                    //     costoAdicional_09: ""
                    // },
                    // {
                    //     linea: "Porción",
                    //     subLinea: "Tomé",
                    //     unidadPerido: "Piezas",
                    //     capacidadPeriodo_01: "30.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "30.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "30.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "30.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "30.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "30.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "30.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "30.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "30.000",
                    //     costoAdicional_09: ""
                    // },
                    // {
                    //     linea: "Sellado Porción congelada individual",
                    //     subLinea: "Tomé",
                    //     unidadPerido: "Porciones",
                    //     capacidadPeriodo_01: "180.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "180.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "180.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "180.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "180.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "180.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "180.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "180.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "180.000",
                    //     costoAdicional_09: ""
                    // },
                    // {
                    //     linea: "Túnel Estático",
                    //     subLinea: "Tomé",
                    //     unidadPerido: "Kilos",
                    //     capacidadPeriodo_01: "45.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "45.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "45.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "45.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "45.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "45.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "45.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "45.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "45.000",
                    //     costoAdicional_09: ""
                    // },
                    // {
                    //     linea: "Túnel Estático",
                    //     subLinea: "Externo",
                    //     unidadPerido: "Kilos",
                    //     capacidadPeriodo_01: "90.000",
                    //     costoAdicional_01: "0,2",
                    //     capacidadPeriodo_02: "90.000",
                    //     costoAdicional_02: "0,2",
                    //     capacidadPeriodo_03: "90.000",
                    //     costoAdicional_03: "0,2",
                    //     capacidadPeriodo_04: "90.000",
                    //     costoAdicional_04: "0,2",
                    //     capacidadPeriodo_05: "90.000",
                    //     costoAdicional_05: "0,2",
                    //     capacidadPeriodo_06: "90.000",
                    //     costoAdicional_06: "0,2",
                    //     capacidadPeriodo_07: "90.000",
                    //     costoAdicional_07: "0,2",
                    //     capacidadPeriodo_08: "90.000",
                    //     costoAdicional_08: "0,2",
                    //     capacidadPeriodo_09: "90.000",
                    //     costoAdicional_09: "0,2"
                    // },
                    // {
                    //     linea: "Túnel Continuo",
                    //     subLinea: "Externo",
                    //     unidadPerido: "Kilos",
                    //     capacidadPeriodo_01: "105.000",
                    //     costoAdicional_01: "",
                    //     capacidadPeriodo_02: "105.000",
                    //     costoAdicional_02: "",
                    //     capacidadPeriodo_03: "105.000",
                    //     costoAdicional_03: "",
                    //     capacidadPeriodo_04: "105.000",
                    //     costoAdicional_04: "",
                    //     capacidadPeriodo_05: "105.000",
                    //     costoAdicional_05: "",
                    //     capacidadPeriodo_06: "105.000",
                    //     costoAdicional_06: "",
                    //     capacidadPeriodo_07: "105.000",
                    //     costoAdicional_07: "",
                    //     capacidadPeriodo_08: "105.000",
                    //     costoAdicional_08: "",
                    //     capacidadPeriodo_09: "105.000",
                    //     costoAdicional_09: ""
                    // }
                ],
                columns: [
                    {
                        label: "Línea",
                        field: "linea"
                    },
                    {
                        label: "Sub línea",
                        field: "subLinea"
                    },
                    {
                        label: "Unidad/Periodo",
                        field: "unidadPerido"
                    },
                    {
                        label: "Capacidad Periodo 1",
                        field: "capacidadPeriodo_01"
                    },
                    {
                        label: "Costo adicional / kg WFE 1",
                        field: "costoAdicional_01"
                    },
                    {
                        label: "Capacidad Periodo 2",
                        field: "capacidadPeriodo_02"
                    },
                    {
                        label: "Costo adicional / kg WFE 2",
                        field: "costoAdicional_02"
                    },
                    {
                        label: "Capacidad Periodo 3",
                        field: "capacidadPeriodo_03"
                    },
                    {
                        label: "Costo adicional / kg WFE 3",
                        field: "costoAdicional_03"
                    },
                    {
                        label: "Capacidad Periodo 4",
                        field: "capacidadPeriodo_04"
                    },
                    {
                        label: "Costo adicional / kg WFE 4",
                        field: "costoAdicional_04"
                    },
                    {
                        label: "Capacidad Periodo 5",
                        field: "capacidadPeriodo_05"
                    },
                    {
                        label: "Costo adicional / kg WFE 5",
                        field: "costoAdicional_05"
                    },
                    {
                        label: "Capacidad Periodo 6",
                        field: "capacidadPeriodo_06"
                    },
                    {
                        label: "Costo adicional / kg WFE 6",
                        field: "costoAdicional_06"
                    },
                    {
                        label: "Capacidad Periodo 7",
                        field: "capacidadPeriodo_07"
                    },
                    {
                        label: "Costo adicional / kg WFE 7",
                        field: "costoAdicional_07"
                    },
                    {
                        label: "Capacidad Periodo 8",
                        field: "capacidadPeriodo_08"
                    },
                    {
                        label: "Costo adicional / kg WFE 8",
                        field: "costoAdicional_08"
                    },
                    {
                        label: "Capacidad Periodo 9",
                        field: "capacidadPeriodo_09"
                    },
                    {
                        label: "Costo adicional / kg WFE 9",
                        field: "costoAdicional_09"
                    }
                ],
                verDetalle: false // para mostrar o no el ver detalle en el componente GoodTable
            }
        };
    }
};
</script>

<style lang="scss">

</style>
